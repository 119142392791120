import request from "../utils/axios"
import {makeSign} from "@/utils/encrypter";

export const confirmOrder = (order) => {
    return request({
        url: '/order/direct/buy/confirm/order/',
        method: 'POST',
        params:order
    })
}

export const generateDirectBuyOrder = (order) => {
    const sign = makeSign()
    return request({
        headers:{
            sign: sign
        },
        url: '/order/generateDirectBuyOrder/',
        method: 'POST',
        data:order,
    })
}

export const unifiedorder = (orderId) => {
    return request({
        //url: '/wxpay/unifiedorder',
        url: '/wxpay/mp/order',
        method: 'POST',
        params: {orderId, tradeType:'jsapi'},
    })
}
export const detail = (orderId) => {
    return request({
        url: '/order/detail/'+orderId,
        method: 'GET',
        params: {orderId},
    })
}
